import type { Options } from 'async-retry';

export const retryConfig: Options = {
	randomize: true,
	retries: 10,
	minTimeout: 1 * 1000,
	maxTimeout: 5 * 1000,
};

export default retryConfig;
