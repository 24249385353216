<template>
	<div v-if="hourtime != ''" class="clock">
		<div class="clock__hours">
			<span class="clock__hourtime" v-text="hourtime"></span>
			<span v-text="hours"></span>
		</div>
		<div class="clock__minutes" v-text="minutes"></div>
		<div class="clock__seconds" v-text="seconds"></div>
	</div>
</template>

<script>
export const SECOND = 1000;
export const HOUR = 12;
export const getHourTime = (hour) => (hour >= 12 ? 'PM' : 'AM');
export const getZeroPad = (number) => (parseInt(number, 10) >= 10 ? '' : '0') + number;

export default {
	data() {
		return {
			hours: 0,
			minutes: 0,
			seconds: 0,
			hourtime: '',
		};
	},
	mounted() {
		this.$options.timer = window.setTimeout(this.updateDateTime, SECOND);
	},
	beforeDestroy() {
		window.clearTimeout(this.$options.timer);
	},
	methods: {
		updateDateTime() {
			const now = new Date();
			this.hours = now.getHours();
			this.minutes = getZeroPad(now.getMinutes());
			this.seconds = getZeroPad(now.getSeconds());
			this.hourtime = getHourTime(this.hours);
			this.hours = this.hours % HOUR || HOUR;
			this.$options.timer = window.setTimeout(this.updateDateTime, SECOND);
		},
	},
};
</script>
