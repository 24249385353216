<template>
	<button class="toggle_track" @click="$emit('toggle')">
		<div class="toggle_track_sun">☀️</div>
		<div class="toggle_track_moon">🌙</div>
		<div class="toggle_track_thumb" :class="{ 'toggle_track_thumb--toggled': toggled }" />
	</button>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
	props: {
		toggled: {
			type: Boolean,
			required: true,
			default: false,
		},
	},
});
</script>
