import React from 'react';
// @ts-ignore
import { VueWrapper } from 'vuera';

// @ts-ignore
import VueLoader from './index.vue';

export const Loader = ({ fullPage = false }: { fullPage?: boolean }) => (
	<div className="react_wrapper_loader">
		<VueWrapper component={VueLoader} fullPage={fullPage} />
	</div>
);

export default Loader;
