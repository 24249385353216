import React, { ReactNode } from 'react';
import { useSession, signIn } from 'next-auth/client';
import { useRouter } from 'next/router';

import { Loader } from '@/components';

interface Props {
	children: ReactNode;
}

export const AuthProvider = ({ children }: Props) => {
	const router = useRouter();
	const [session, loading] = useSession();

	if (loading) {
		return <Loader fullPage />;
	}

	if (!session && router.pathname !== '/top-secret-page') {
		signIn();

		return <Loader fullPage />;
	}

	return <>{children}</>;
};

export default AuthProvider;
