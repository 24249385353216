<template>
	<footer>
		<Toggle :toggled="darkMode" @toggle="toggleDarkMode" />
	</footer>
</template>

<script lang="ts">
import Vue from 'vue';

import Toggle from '../Toggle/index.vue';

export default Vue.extend({
	name: 'Footer',
	components: { Toggle },
	props: {
		toggleDarkMode: {
			type: Function,
			required: true,
		},
		darkMode: {
			type: Boolean,
			required: true,
		},
	},
});
</script>
