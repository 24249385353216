import React from 'react';
import { Head as NextDocumentHead } from 'next/document';
import NextHead from 'next/head';

const _getMeta = () => {
	return (
		<>
			<meta
				name="viewport"
				content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, viewport-fit=cover"
			/>
			<meta charSet="utf-8" />
			<link rel="author" href="humans.txt" />
			<meta httpEquiv="X-UA-Compatible" content="chrome=1" />
			<meta name="classification" content="Website" />
			<meta property="og:type" content="website" />
			<meta name="rating" content="General" />
			<meta name="revisit-after" content="7 days" />
			<meta httpEquiv="Expires" content="0" />
			<meta httpEquiv="Pragma" content="no-cache" />
			<meta httpEquiv="Cache-Control" content="no-cache" />
			<meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
			<meta httpEquiv="content-language" content="nl" />
			<meta name="application-name" content="Portfolio S8 – Max Altena" />
			<meta name="author" content="Max Altena <max@maxaltena.com>" />
			<meta name="designer" content="Max Altena" />
			<meta name="owner" content="Max Altena" />
			<meta
				name="abstract"
				content="The portfolio of Max Altena for Semester 8 which was a graduation internship at Stijlbreuk"
			/>
			<meta
				name="description"
				content="The portfolio of Max Altena for Semester 8 which was a graduation internship at Stijlbreuk"
			/>
			<meta
				name="summary"
				content="The portfolio of Max Altena for Semester 8 which was a graduation internship at Stijlbreuk"
			/>
			<meta
				property="og:description"
				content="The portfolio of Max Altena for Semester 8 which was a graduation internship at Stijlbreuk"
			/>
			<meta
				name="twitter:card"
				content="The portfolio of Max Altena for Semester 8 which was a graduation internship at Stijlbreuk"
			/>
		</>
	);
};

const _getTitle = (title?: string, useTitleTemplate = true) => {
	let titleOutput = '';

	if (title && useTitleTemplate) {
		titleOutput = `${title} – Portfolio S8 – Max Altena`;
	} else if (title && !useTitleTemplate) {
		titleOutput = title;
	} else {
		titleOutput = 'Portfolio S8 – Max Altena';
	}

	return <title>{titleOutput}</title>;
};

export const HeadNext = ({
	icon,
	title,
	useTitleTemplate = true,
}: {
	icon?: React.ReactNode;
	title: string;
	useTitleTemplate?: boolean;
}): JSX.Element => {
	return (
		<NextHead>
			{_getTitle(title, useTitleTemplate)}
			{icon || <link rel="shortcut icon" href="/favicon.ico" />}
		</NextHead>
	);
};

export const HeadDocument = (): JSX.Element => {
	return <NextDocumentHead>{_getMeta()}</NextDocumentHead>;
};

export const Head = HeadNext;

export default Head;
