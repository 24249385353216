import { parsePageId } from '@/utils/notion/parsePageId';
import { isDev } from '@/utils/config/environment';
import { PageUrlOverridesInverseMap, PageUrlOverridesMap } from '@/types';

export const cleanPageUrlMap = (pageUrlMap: PageUrlOverridesMap, label: string): PageUrlOverridesMap => {
	return Object.keys(pageUrlMap).reduce((acc, uri) => {
		const pageId = pageUrlMap[uri];
		const uuid = parsePageId(pageId, { uuid: false });

		if (!uuid) {
			throw new Error(`Invalid ${label} page id "${pageId}"`);
		}

		if (!uri) {
			throw new Error(`Missing ${label} value for page "${pageId}"`);
		}

		if (!uri.startsWith('/')) {
			throw new Error(
				`Invalid ${label} value for page "${pageId}": value "${uri}" should be a relative URI that starts with "/"`,
			);
		}

		const path = uri.slice(1);

		return {
			...acc,
			[path]: uuid,
		};
	}, {});
};

export const invertPageUrlOverrides = (pageUrlOverrides: PageUrlOverridesMap): PageUrlOverridesInverseMap => {
	return Object.keys(pageUrlOverrides).reduce((acc, uri) => {
		const pageId = pageUrlOverrides[uri];

		return {
			...acc,
			[pageId]: uri,
		};
	}, {});
};

export const notionAuthToken = process.env.NOTION_AUTH_TOKEN || '';

export const rootNotionPageId: string | null = parsePageId('961961e4286e4420b2c2d99967c07e71', { uuid: false });

if (!rootNotionPageId) {
	throw new Error('Config error invalid "rootNotionPageId"');
}

export const rootNotionSpaceId: string | null = parsePageId('4e3f7061-ba21-493d-b2d6-184590164883', { uuid: true });

export const notFoundNotionPageId: string | null = parsePageId('087a382d4b7a42a685475d954b84f6f2', { uuid: false });

export const errorNotionPageId: string | null = parsePageId('8f626a39cbd2431399b0aadc19b49906', { uuid: false });

export const pageUrlOverrides = cleanPageUrlMap({}, 'pageUrlOverrides');

export const inversePageUrlOverrides = invertPageUrlOverrides(pageUrlOverrides);

export const pageUrlAdditions = cleanPageUrlMap({}, 'pageUrlAdditions');

export const includeNotionIdInUrls = !!isDev;

export const notionConfig = {
	notionAuthToken,
	rootNotionPageId,
	rootNotionSpaceId,
	pageUrlOverrides,
	inversePageUrlOverrides,
	pageUrlAdditions,
	includeNotionIdInUrls,
};

export default notionConfig;
