<template>
	<div v-if="fullPage" class="loader loader-fullpage">
		<div class="loader_spinner" />
	</div>
	<div v-else class="loader">
		<div class="loader_spinner" />
	</div>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
	name: 'Loader',
	props: {
		fullPage: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
});
</script>
