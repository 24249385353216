import React from 'react';
// @ts-ignore
import { VueWrapper } from 'vuera';

// @ts-ignore
import App from './index.vue';

export const VueApp = ({ math }: { math: string }): JSX.Element => <VueWrapper component={App} content={math} />;

export default VueApp;
