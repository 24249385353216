<template>
	<Clock v-if="componentType === 'clock'" />
	<p v-else-if="componentType === 'text'">
		{{ componentText }}
	</p>
	<div v-else>
		<div>notion content: {{ content }}</div>
		<div>tech: {{ componentTech }}</div>
		<div>type: {{ componentType }}</div>
	</div>
</template>

<script lang="ts">
import Vue from 'vue';

import Clock from '../Clock/index.vue';

export default Vue.extend({
	name: 'App',
	components: { Clock },
	props: {
		content: {
			type: String,
			required: true,
		},
	},
	computed: {
		componentTech() {
			return this.content.split('::')[0];
		},
		componentType() {
			return this.content.split('::')[1];
		},
		componentText() {
			return this.content.split('::')[2];
		},
	},
});
</script>
