import type { ExtendedRecordMap } from 'notion-types';

import { uuidToId } from '@/utils/notion/uuidToId';
import { parsePageId } from '@/utils/notion/parsePageId';
import { getCanonicalPageId } from '@/utils/notion/getCanonicalPageId';
import { includeNotionIdInUrls } from '@/utils/config/notion';
import type { Site } from '@/types';

// include UUIDs in page URLs during local development but not in production
// (they're nice for debugging and speed up local dev)
const uuid = !!includeNotionIdInUrls;

export const mapPageUrl =
	(site: Site, recordMap: ExtendedRecordMap) =>
	(pageId = '') => {
		if (uuidToId(pageId) === site.rootNotionPageId) {
			return createUrl('/');
		} else {
			return createUrl(`/${getCanonicalPageId(pageId, recordMap, { uuid })}`);
		}
	};

export const getCanonicalPageUrl =
	(site: Site, recordMap: ExtendedRecordMap) =>
	(pageId = '') => {
		const pageUuid = parsePageId(pageId, { uuid: true });

		if (uuidToId(pageId) === site.rootNotionPageId) {
			return `https://${site.domain}`;
		} else {
			return `https://${site.domain}/${getCanonicalPageId(pageUuid || '', recordMap, {
				uuid,
			})}`;
		}
	};

const createUrl = (path: string) => [path].filter(Boolean).join('?');

export default mapPageUrl;
