import React from 'react';
// @ts-ignore
import { VueWrapper } from 'vuera';

// @ts-ignore
import VueToggle from './index.vue';

export const Toggle = ({ toggled = false, onToggle = () => null }: { toggled?: boolean; onToggle?: () => void }) => (
	<div className="react_wrapper_toggle">
		<VueWrapper component={VueToggle} toggled={toggled} toggle={onToggle} />
	</div>
);

export default Toggle;
