import { getEnv } from '@/utils/config/getEnv';

export const name = 'Afstudeerstage Max Altena @ Stijlbreuk';
export const author = 'Max Altena';
export const description = 'De afstudeerstage van Max Altena bij Stijlbreuk';
export const domain = 'portfolio-s8.maxaltena.com';

export const isDev = process.env.NODE_ENV === 'development' || !process.env.NODE_ENV;

export const isServer = typeof window === 'undefined';

export const port = getEnv('PORT', '3000');
export const host = isDev ? `http://localhost:${port}` : `https://${domain}`;

export const apiBaseUrl = `${host}/api`;

export const environmentConfig = {
	name,
	author,
	domain,
	description,
	isDev,
	isServer,
	port,
	host,
	apiBaseUrl,
};

export default environmentConfig;
