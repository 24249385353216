import { environmentConfig } from '@/utils/config/environment';
import { notionConfig } from '@/utils/config/notion';
import { retryConfig } from '@/utils/config/retry';

export * from './environment';
export * from './notion';
export * from './retry';
export * from './getEnv';

export const config = {
	env: environmentConfig,
	notion: notionConfig,
	retry: retryConfig,
};

export default config;
