import React, { FC } from 'react';
import Head from 'next/head';
import Link, { LinkProps } from 'next/link';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import useDarkMode from 'use-dark-mode';

import { Loader } from '@/components';
import { description, isDev, isServer } from '@/utils/config';
import type { PageProps } from '@/types';
import { getBlockTitle } from '@/utils/notion/getBlockTitle';
import { getPageDescription } from '@/utils/notion/getPageDescription';
import { mapPageUrl, getCanonicalPageUrl } from '@/utils/notion/mapPageUrl';

const NotionRenderer = dynamic(async () => (await import('react-notion-x')).NotionRenderer);
const Footer = dynamic(async () => (await import('@/components')).Footer);
const VueApp = dynamic(async () => (await import('@/components')).VueApp);
const Code = dynamic(async () => (await import('react-notion-x')).Code);
const Collection = dynamic(async () => (await import('react-notion-x')).Collection);
const CollectionRow = dynamic(async () => (await import('react-notion-x')).CollectionRow);
const Modal = dynamic(async () => (await import('react-notion-x')).Modal, { ssr: false });

export const NotionPage: FC<PageProps> = ({ site, recordMap, error, pageId }) => {
	const router = useRouter();

	const params: any = {};

	const darkMode = useDarkMode(undefined, { classNameDark: 'dark-mode', element: document.documentElement });

	if (router.isFallback) {
		return <Loader fullPage />;
	}

	const keys = Object.keys(recordMap?.block || {});
	const block = recordMap?.block?.[keys[0]]?.value;

	if (error || !site || !keys.length || !block) {
		router.push('/404');
		return <Loader fullPage />;
	}

	if (recordMap) {
		const title = getBlockTitle(block, recordMap) || site.name;

		if (!isServer) {
			const globalWindow = window as any;
			globalWindow.pageId = pageId;
			globalWindow.recordMap = recordMap;
			globalWindow.block = block;
		}

		const siteMapPageUrl = mapPageUrl(site, recordMap);

		const canonicalPageUrl = !isDev && getCanonicalPageUrl(site, recordMap)(pageId);

		const socialDescription = getPageDescription(block, recordMap) ?? description;

		return (
			<>
				<Head>
					<meta property="og:title" content={title} />
					<meta property="og:site_name" content={site.name} />

					<meta name="twitter:title" content={title} />
					<meta property="twitter:domain" content={site.domain} />

					{socialDescription && (
						<>
							<meta name="description" content={socialDescription} />
							<meta property="og:description" content={socialDescription} />
							<meta name="twitter:description" content={socialDescription} />
						</>
					)}

					{canonicalPageUrl && (
						<>
							<link rel="canonical" href={canonicalPageUrl} />
							<meta property="og:url" content={canonicalPageUrl} />
							<meta property="twitter:url" content={canonicalPageUrl} />
						</>
					)}

					<title>{title}</title>
				</Head>

				<NotionRenderer
					components={{
						pageLink: ({ href, as, passHref, prefetch, replace, scroll, shallow, locale, ...props }: LinkProps) => (
							<Link
								href={href}
								as={as}
								passHref={passHref}
								prefetch={prefetch}
								replace={replace}
								scroll={scroll}
								shallow={shallow}
								locale={locale}
							>
								<a {...props} />
							</Link>
						),
						code: Code,
						collection: Collection,
						collectionRow: CollectionRow,
						modal: Modal,
						equation: VueApp,
					}}
					recordMap={recordMap}
					rootPageId={site.rootNotionPageId}
					fullPage={true}
					darkMode={darkMode.value}
					showCollectionViewDropdown={true}
					mapPageUrl={siteMapPageUrl}
					pageFooter={<div className="pageFooter"></div>}
					pageAside={<aside className="pageAside"></aside>}
					footer={<Footer />}
				/>
			</>
		);
	} else {
		return <p>Er ging iets mis...</p>;
	}
};

export default NotionPage;
