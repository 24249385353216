import React from 'react';
import useDarkMode from 'use-dark-mode';
// @ts-ignore
import { VueWrapper } from 'vuera';

// @ts-ignore
import VueFooter from './index.vue';

export const Footer = () => {
	const darkMode = useDarkMode(undefined, { classNameDark: 'dark-mode', element: document.documentElement });

	return (
		<div className="react_wrapper_footer">
			<VueWrapper component={VueFooter} toggleDarkMode={darkMode.toggle} darkMode={darkMode.value} />
		</div>
	);
};

export default Footer;
