import type { ExtendedRecordMap } from 'notion-types';

import { uuidToId } from '@/utils/notion/uuidToId';
import { getBlockTitle } from '@/utils/notion/getBlockTitle';
import { parsePageId } from '@/utils/notion/parsePageId';
import { inversePageUrlOverrides } from '@/utils/config/notion';

export const getCanonicalPageId = (
	pageId: string,
	recordMap: ExtendedRecordMap,
	{ uuid = true }: { uuid?: boolean } = {},
): string | null => {
	const cleanPageId = parsePageId(pageId, { uuid: false });
	if (!cleanPageId) {
		return null;
	}

	const override = inversePageUrlOverrides[cleanPageId];
	if (override) {
		return override;
	} else {
		return getCanonicalPageIdImpl(pageId, recordMap, {
			uuid,
		});
	}
};

export default getCanonicalPageId;

/**
 * Gets the canonical, display-friendly version of a page's ID for use in URLs.
 */
export const getCanonicalPageIdImpl = (
	pageId: string,
	recordMap: ExtendedRecordMap,
	{ uuid = true }: { uuid?: boolean } = {},
): string | null => {
	if (!pageId || !recordMap) {
		return null;
	}

	const id = uuidToId(pageId);
	const block = recordMap.block[pageId]?.value;

	if (block) {
		const title = normalizeTitle(getBlockTitle(block, recordMap));

		if (title) {
			if (uuid) {
				return `${title}-${id}`;
			} else {
				return title;
			}
		}
	}

	return id;
};

export const normalizeTitle = (title: string | null): string => {
	return (title || '')
		.replace(/ /g, '-')
		.replace(/[^a-zA-Z0-9-]/g, '')
		.replace(/--/g, '-')
		.replace(/-$/, '')
		.replace(/^-/, '')
		.trim()
		.toLowerCase();
};
